/* eslint @typescript-eslint/no-unused-vars: 0 */
import { EnvFlags, disableEnvs, enableEnvs, mapFeatures } from '@helper/functions/feature-flags';

/**
 * #### Define the feature flags here
 *
 * Outdated or in-production features should have their flags removed to keep it tidy.
 */
export type FeatureFlags<T> = {
  nonProdWarning: T;
  roles: {
    cashier: T;
  };
  paymentMethods: {
    cash: T;
    cheque: T;
    terminal: T;
  };
  services: {
    accommodation: T;
    cremation: T;
    education: T;
    staffPayments: T;
    nswHealthMap: T;
    medicalRecords: T;
    mockCheckout: T;
    pbrc: T;
  };
  admin: {
    outageManager: T;
    cashierReport: T;
    ratingReport: T;
    medicalRecords: T;
    organisations: T;
    devTools: T;
    cashierAccess: T;
    QRCodeGeneration: T;
    thirdPartyPayment: T;
  };
  userSettings: T;
};

/**
 * #### Toggle the flags here
 *
 * - To partially-enabled a feature:
 *
 * ```
 * awesomeFeature: enableEnvs(['dev', 'test'])    // ✅ Only enable this feature in DEV and TEST instances.
 * testingFeature: disableEnvs(['stg'])           // ✅ Only disable this feature in STG, enable it in ALL other envs.
 *
 * ```
 *
 *
 * - If a feature should be globally enabled or disabled, you can set a boolean value.
 *
 * ```
 * awesomeFeature: true;     // ✅ Globally enabled
 * testingFeature: false;    // ✅ Globally disabled
 * ```
 */
const features: FeatureFlags<EnvFlags | boolean> = {
  nonProdWarning: disableEnvs(['prod']),
  roles: {
    cashier: true,
  },
  paymentMethods: {
    cash: true,
    cheque: true,
    terminal: true,
  },
  services: {
    accommodation: false,
    cremation: false,
    education: false,
    staffPayments: false,
    medicalRecords: false,
    mockCheckout: enableEnvs(['dev']),
    nswHealthMap: enableEnvs(['dev']),
    pbrc: enableEnvs(['dev']),
  },
  admin: {
    outageManager: true,
    cashierReport: true,
    ratingReport: false,
    cashierAccess: true,
    QRCodeGeneration: enableEnvs(['dev']),
    medicalRecords: false,
    organisations: false,
    devTools: false,
    thirdPartyPayment: enableEnvs(['dev']),
  },
  userSettings: true,
};

/**
 * #### Map and export flags
 *
 * Do not modify below.
 */
const Features = mapFeatures(features);
export default Features;
